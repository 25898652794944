import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import MenuReset from "../components/MenuReset"

import src from "../images/iStock-610851582.jpg"
import PageIntro from "../components/pageIntro"

const AboutPage = () => (
  <Layout solidHeader>
    <SEO title="Applied Kinesiology" />
    <MenuReset />

    <PageIntro>
      <h2>What is Applied Kinesiology?</h2>
    </PageIntro>

    <Content>
      <div className="row">
        <div className="col">
          <div className="content">
            <p>
              Applied Kinesiology (AK) is a scientific method of assessing
              muscle function in order to gain feedback from the nervous system
              about the functioning of your body. AK provides us with real time
              feedback of how your system functions on a structural,
              physiological and biochemical level. When AK is combined with
              traditional orthopaedic, physical examination and laboratory
              assessment, it becomes a very powerful diagnostic tool.
            </p>
            <p>
              Applied Kinesiology is used to look for functional changes in
              muscle performance. Dr. David Abagia (osteopath) is trained in the
              use of AK and is able to identify more specific information in
              regards to the cause of the problem. This is achieved by
              monitoring muscle response after applying a force to a specific
              muscle. In some circumstance, AK can help reveal the location of
              your problem whether it is structural, neurological or
              physiological.
            </p>
            <h3>Who uses Applied Kinesiology?</h3>
            <p>
              Although Applied Kinesiology was originally founded by a
              Chiropractor, many other professions study and practice Applied
              Kinesiology. These include Osteopaths, Medical Practitioners and
              Dentists. In Australia and the USA the majority of people who use
              Applied Kinesiology are Chiropractors. However in Germany and
              several other countries across Europe, there are thousands of
              Medical Doctors and Osteopaths who are trained in this diagnostic
              art form.
            </p>
          </div>
        </div>
        <div className="col">
          <img src={src} alt="Exercise" />
        </div>
      </div>
    </Content>
  </Layout>
)

export default AboutPage
